// Dependency
import React from "react"
import {Link} from "gatsby";
import Img from "gatsby-image";
import AnchorLink from "react-anchor-link-smooth-scroll";


// Styles
import * as tilesStyles from "./tiles.module.scss";

// Template
const Tile = (props) => {
    return (

            props && props.data.isAnchor ?
                <AnchorLink offset="10" href={props.data.link} className={tilesStyles.cdTilesCard}>
                    <div>
                        <p>{props.data.title}</p>
                    </div>
                </AnchorLink>
                :
                <Link to={`/${props.country}/${props.data.link}/`} className={tilesStyles.cdTilesCard}>
                    <div className={tilesStyles.cdTilesCardImgWrapper}>
                        <div className={` ${tilesStyles.cdTilesCardImg} cd-background-img`}>
                            {props.data && props.data.featuredMedia && <Img alt="" fluid={props.data.featuredMedia.fluid} /> }
                        </div>
                    </div>
                    <div>
                        <p>{props.data.title}</p>
                    </div>
                </Link>
    )

};

export default Tile;