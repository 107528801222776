// Dependency
import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

// Styles
import 'swiper/scss';
import * as tilesStyles from './tiles.module.scss'

// Component
import Tile from "./tile";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

// Template
const Tiles = (props) => {

    return (

        <section className={`${tilesStyles.cdTilesSection} cd-dark-mode-black`}>
            <div className="cd-max-width">
                <div className="cd-swiper-wrapper">
                    <Swiper className={`cd-swiper`}
                        spaceBetween={16}
                        slidesPerView={'auto'}
                        slidesPerGroup={1}
                        grabCursor={true}
                        centerInsufficientSlides={true}
                        watchSlidesProgress={true}
                        breakpoints={{
                            900:{
                                spaceBetween: 0,
                                slidesPerView: 'auto',
                                slidesPerGroup: 1,                                
                            },
                        }}
                    >
                        {props.data.map( (tile, index) => {
                            return (
                                <SwiperSlide key={index.toString()} className={tilesStyles.swiperSlide}>
                                    <Tile data={tile} country={props.country} />
                                </SwiperSlide>
                            )
                        } )}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Tiles
